<template>
  <div v-if="$can('order_list', 'order')">
    <v-card>
      <v-form ref="searchForm" @submit.prevent="toSearch()">
        <v-card-text class="d-flex flex-row-reverse pb-0">
          <div class="d-flex align-center">
            <v-btn
              v-if="$can('order_export', 'order')"
              class="me-3"
              color="success"
              :disabled="overlay"
              :loading="overlay"
              @click="exportOrder()"
            >
              <span>导出订单</span>
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn class="me-3" @click="resetSearch()">
              <span>重置</span>
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn color="secondary" class="me-3" type="submit">
              <v-icon size="18" class="me-1">
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0" style="width: 280px">
            <v-text-field v-model="search.name" outlined dense label="订单编号/收货人/收货人电话"></v-text-field>
          </div>
          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick v-model="search.endTime" :hide="false" label="结束时间"></DatePick>
          </div>
          <div class="d-flex align-center mr-5">
            <span>至</span>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick v-model="search.startTime" :hide="false" label="开始时间"></DatePick>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-title class="">
        <v-tabs v-model="currentTab" style="box-shadow: none !important">
          <v-tab @click="reloadTab(0)"> 待确认({{ orderCount.waitConfirmCount }}) </v-tab>
          <v-tab @click="reloadTab(2)"> 待支付({{ orderCount.waitPayCount }}) </v-tab>
          <v-tab @click="reloadTab(3)"> 待发货({{ orderCount.waitDeliveryCount }}) </v-tab>
          <v-tab @click="reloadTab(4)"> 已发货({{ orderCount.waitReceiveCount }}) </v-tab>
          <v-tab @click="reloadTab(5)"> 已完成({{ orderCount.completedCount }}) </v-tab>
          <v-tab @click="reloadTab(1)"> 已取消({{ orderCount.cancelCount }}) </v-tab>
          <v-tab @click="reloadTab(7)"> 已拒绝({{ orderCount.rejectCount }}) </v-tab>
          <v-tab @click="reloadTab(null)"> 全部订单({{ orderCount.allCount }}) </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true,
          }"
          disable-filtering
          :headers="getHeader()"
          :items="orders"
          :items-per-page="pages.itemsPerPage"
          :page="pages.page"
          :loading="loadings.tableloading"
          loading-text="数据加载中..."
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          v-model="selected"
          :single-select="false"
          item-key="orderNo"
          show-select
          @pagination="loadPage"
          @update:options="updateOptions"
        >
          <template #[`item.company`]="{ item }">
            <v-row>
              <v-col class="pa-2 align-self-center" cols="auto">
                <v-avatar size="33">
                  <img :src="item.receiverAvatar || require('@/assets/images/avatars/1.png')" />
                </v-avatar>
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.companyName }} </span><br />
                <span class="text-caption">{{ item.receiverName }} {{ item.receiverPhone }}</span>
              </v-col>
            </v-row>
          </template>

          <template #[`item.orderPrice`]="{ item }">
            <span>￥{{ item.orderPrice }}</span>
          </template>

          <template #[`item.orderStatus`]="{ item }">
            <v-chip v-if="item.orderStatus === 2" color="info" small class="font-weight-semibold text-capitalize">
              待付款
            </v-chip>
            <v-chip
              v-else
              :color="getOrderStatusColor(item.orderStatus)"
              small
              class="font-weight-semibold text-capitalize"
            >
              {{ getOrderStatusByValue(item.orderStatus) }}
            </v-chip>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <span v-if="$can('order_toggle_status', 'order')">
              <!-- 生成按钮 如果status为2 那么按钮就是设为待确认，如果status为3 那么按钮就是设为已收款 如果status为5 那么按钮就是设为已发货 如果status为0 那么他就有 确认按钮 和取消按钮 -->
              <v-btn
                v-if="item.orderStatus === 0"
                text
                dense
                x-small
                color="primary"
                @click="toggleStatus(item.orderNo, 2)"
              >
                确认
              </v-btn>
              <v-btn
                v-if="item.orderStatus === 0"
                text
                dense
                x-small
                color="primary"
                @click="toggleStatus(item.orderNo, 1)"
              >
                取消
              </v-btn>
              <v-btn
                v-if="item.orderStatus === 1"
                text
                dense
                x-small
                color="primary"
                @click="toggleStatus(item.orderNo, 0)"
              >
                设为待确认
              </v-btn>
              <v-btn
                v-if="item.orderStatus === 2"
                text
                dense
                x-small
                color="primary"
                @click="toggleStatus(item.orderNo, 3)"
              >
                设为已收款
              </v-btn>
              <!-- <v-btn
                v-if="item.orderStatus === 3"
                text
                dense
                x-small
                color="primary"
                @click="toggleStatus(item.orderNo, 4)"
              >
                设为已发货
              </v-btn> -->
            </span>
            <v-btn v-if="$can('order_detail', 'order')" text dense x-small color="primary" @click="editItem(item)">
              详情
            </v-btn>
            <!-- <v-btn
              v-if="$can('banner_delete', 'banner')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '80%' : '100%'"
      height="100%"
      app
    >
      <div class="drawer-header d-flex align-center">
        <span class="font-weight-semibold text-base text-h6">订单详情</span>
        <v-spacer></v-spacer>

        <v-btn icon small @click="editDialog = false">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-row style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
        <v-col cols="12" md="4">
          <v-card class="pt-2 ml-5 overflow-auto overflow-auto">
            <v-card-title class="justify-center flex-column">
              <v-avatar color="primary" class="v-avatar-light-bg primary--text" size="120" rounded>
                <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
              </v-avatar>
              <h4 class="mb-2 mt-3">
                {{ userInfo.userName }}
              </h4>

              <span class="mb-2 text-caption">{{ userInfo.memberName }}</span>
            </v-card-title>

            <v-card-text>
              <p class="text-h6">订货信息</p>

              <v-divider></v-divider>

              <v-list>
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">订货金额</span>
                  <span class="text--secondary">¥{{ order.orderPrice }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium text-no-wrap me-2">下单时间</span>
                  <span class="text--secondary">{{ order.createdTime }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">订单编号</span>
                  <span class="text--secondary text-capitalize">{{ order.orderNo }}</span>
                </v-list-item>

                <v-list-item v-if="$can('order_toggle_status', 'order')" dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">订货状态</span>

                  <!-- 当前状态 -->
                  <v-chip
                    :color="getOrderStatusColor(order.orderStatus)"
                    small
                    class="font-weight-semibold text-capitalize"
                  >
                    {{ getOrderStatusByValue(order.orderStatus) }}
                  </v-chip>

                  <!-- 下拉框 -->
                  <v-select
                    class="ml-2"
                    v-model="statusValue"
                    :items="statusListArray"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    label="修改状态"
                    hide-details
                    @change="changeOrderStatus"
                  ></v-select>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-text>
              <p class="text-h6">订货单位</p>

              <v-divider></v-divider>

              <v-list>
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">单位名称</span>
                  <span class="text--secondary">{{ companyDetail.companyName }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium text-no-wrap me-2">收件人</span>
                  <span class="text--secondary">{{ companyDetail.receiverName }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">收货地址</span>
                  <span class="text--secondary text-capitalize"
                    >{{ companyDetail.receiverAddr }}{{ companyDetail.receiverAddrDetail }}</span
                  >
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">发票类型</span>
                  <span class="text--secondary text-capitalize">{{
                    companyDetail.invoiceType === 0 ? '普通发票' : '增值税发票'
                  }}</span>
                </v-list-item>
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">发票抬头</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.companyName }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">单位税号</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.invoiceNo }}</span>
                </v-list-item>
              </v-list>
            </v-card-text>
            <!-- 订单备注 -->
            <v-card-text>
              <p class="text-h6">订单备注</p>

              <v-divider></v-divider>

              <v-list>
                <v-list-item dense class="px-0 mb-n2">
                  <v-textarea
                    v-if="order.orderStatus == 0 || order.orderStatus == 2 ? true : false"
                    row-height="25"
                    :disabled="
                      order.orderStatus == 0 || order.orderStatus == 2 ? false : true
                    "
                    outlined
                    :label="
                      order.orderStatus == 0 || order.orderStatus == 2
                        ? !!order.orderMemo
                          ? '备注'
                          : '请输入备注信息'
                        : '备注'
                    "
                    @blur="changeOrderMemo(order.orderMemo)"
                    v-model="order.orderMemo"
                  ></v-textarea>
                  <span v-else class="px-0 mt-2">
                    {{ order.orderMemo }}
                  </span>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <p class="text-h6">商品列表</p>
                </v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">商品描述</th>
                          <th class="text-left">数量</th>
                          <th class="text-left">原价</th>
                          <th class="text-left">会员价</th>
                          <th class="text-left">结算价</th>
                          <th class="text-left">小计</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in orderDetails" :key="index">
                          <td>
                            <v-row>
                              <v-col class="pa-2 align-self-center" cols="auto">
                                <v-avatar size="33">
                                  <img :src="item.productImg" />
                                </v-avatar>
                              </v-col>
                              <v-col class="pl-0">
                                <span class="text-subtitle-2">{{ item.productName }} </span><br />
                                <span class="text-caption">{{ item.productSkuTitle }}</span>
                              </v-col>
                            </v-row>
                          </td>
                          <td v-if="order.orderStatus === 0 && $can('order_modify_quantity', 'order')">
                            <v-text-field
                              :value="item.productSkuQuantity"
                              dense
                              style="width: 50px"
                              type="number"
                              hide-details
                              @change="modifyQuantity($event, item)"
                            ></v-text-field>
                          </td>
                          <td v-else>
                            {{ item.productSkuQuantity }}
                          </td>
                          <td>¥{{ parseFloat(item.productSkuPrice).toFixed(2) }}</td>
                          <td>¥{{ parseFloat(item.productSkuMemberPrice).toFixed(2) }}</td>
                          <!-- <td>¥{{ parseFloat(item.settlementPrice).toFixed(2) }}</td> -->
                          <td v-if="order.orderStatus === 0 && $can('order_modify_settlement_price', 'order')">
                            <v-text-field
                              :value="item.settlementPrice"
                              dense
                              prefix="¥"
                              hide-details
                              style="width: 75px"
                              type="number"
                              @change="modifySettlementPrice($event, item)"
                            ></v-text-field>
                          </td>
                          <td v-else>¥{{ parseFloat(item.settlementPrice).toFixed(2) }}</td>
                          <td>¥{{ parseFloat(item.subTotalPrice).toFixed(2) }}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>合计</td>
                          <!-- <td>¥{{ parseFloat(order.orderPrice).toFixed(2) }}</td> -->
                          <td v-if="order.orderStatus === 0 && $can('order_modify_order_price', 'order')">
                            <v-text-field
                              :value="order.orderPrice"
                              dense
                              prefix="¥"
                              hide-details
                              style="width: 75px"
                              type="number"
                              @change="modifyOrderPrice($event, order)"
                            ></v-text-field>
                          </td>
                          <td v-else>¥{{ parseFloat(order.orderPrice).toFixed(2) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="overflow-auto">
                <v-card-title>
                  <p class="text-h6">订单详情</p>
                </v-card-title>

                <v-card-text>
                  <!-- 给个timeline  左边显示 并且带图标 -->
                  <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
                    <!-- Timeline Item: Flight -->
                    <v-timeline-item v-for="(record, index) in orderRecords" :key="index" color="transparent">
                      <template #icon>
                        <v-avatar color="primary" class="v-avatar-light-bg primary--text" size="40">
                          <v-icon size="24" color="primary">
                            {{ icons.mdiFileDocumentOutline }}
                          </v-icon>
                        </v-avatar>
                      </template>
                      <!-- Header -->
                      <div class="d-flex justify-space-between">
                        <p class="mb-1 text-caption text--primary">
                          {{ record.createdTime }}
                        </p>
                      </div>

                      <!-- Content -->
                      <p v-if="record.logType === 'STATUS'" class="mb-1">
                        <span class="text-subtitle-2"
                          >{{ record.createdBy }} 修改订单状态
                          <span style="color: rgb(0, 187, 255)">{{ record.statusDesc }}</span>
                        </span>
                      </p>
                      <p v-for="(log, index) in record.logData" :key="index" class="mb-1">
                        <template v-if="record.logType === 'PRODUCT'">
                          <span class="text-subtitle-2"
                            >{{ record.createdBy }} 修改 {{ record.objName }}
                            <span v-if="log.fieldName === 'quantity'">：数量 </span>
                            <span v-if="log.fieldName === 'settlementPrice'">：结算价 </span>
                            <span style="color: rgb(0, 187, 255)">{{ log.beforeValue }} => {{ log.afterValue }}</span>
                          </span>
                        </template>
                      </p>

                      <p v-for="(log, index) in record.logData" :key="index" class="mb-1">
                        <template v-if="record.logType === 'ORDER'">
                          <span v-if="log.fieldName === 'orderPrice'" class="text-subtitle-2"
                            >{{ record.createdBy }} 修改订单
                            <span>：总计 </span>
                            <span style="color: rgb(0, 187, 255)">{{ log.beforeValue }} => {{ log.afterValue }}</span>
                          </span>
                          <span v-if="log.fieldName === 'settlementPrice'" class="text-subtitle-2">
                            <span>{{ log.value }}：结算价 </span>
                            <span style="color: rgb(0, 187, 255)">{{ log.beforeValue }} => {{ log.afterValue }}</span>
                          </span>
                        </template>
                      </p>
                    </v-timeline-item>

                    <v-timeline-item color="transparent">
                      <template #icon>
                        <v-avatar color="primary" class="v-avatar-light-bg primary--text" size="40">
                          <v-icon size="24" color="primary">
                            {{ icons.mdiFileDocumentOutline }}
                          </v-icon>
                        </v-avatar>
                      </template>
                      <!-- Header -->
                      <div class="d-flex justify-space-between">
                        <p class="mb-1 text-caption text--primary">
                          {{ order.createdTime }}
                        </p>
                      </div>

                      <!-- Content -->
                      <p class="mb-1">
                        <span class="text-subtitle-2">{{ userInfo.userName }} 创建了订单</span>
                      </p>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-text>
          确定删除订单:<span style="color: red">{{ deleteData.orderNo }}</span
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItem()"> 确认删除 </v-btn>
          <v-btn color="normal" text @click="deleteDialog = false"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay"></v-overlay>
  </div>
</template>

<script>
import { mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose, mdiLinkVariant, mdiFileDocumentOutline } from '@mdi/js'
import DatePick from '@/components/DatePicker'
import OrderApi from '@/api/order'

export default {
  name: 'Order',
  components: {
    DatePick,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiLinkVariant,
        mdiFileDocumentOutline,
      },
      editDialog: false,
      deleteDialog: false,
      orders: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      search: {},
      editedItem: {
        startTime: '',
        endTime: '',
      },
      currentTab: 0,
      status: 0,
      deleteData: {},
      orderCount: {
        allCount: 0,
        waitConfirmCount: 0,
        waitPayCount: 0,
        waitDeliveryCount: 0,
        waitReceiveCount: 0,
        completedCount: 0,
        cancelCount: 0,
      },
      loadings: {
        tableloading: false,
      },
      order: {},
      editOrder: {},
      orderDetails: [],
      orderRecords: [],
      orderMemoinfos: null,
      companyDetail: {},
      userInfo: {
        memberName: '',
        userName: '',
      },
      statusList: [
        { text: '待确认', color: 'danger', value: 0 },
        { text: '已取消', color: '', value: 1 },
        { text: '已通过', color: 'info', value: 2 },
        { text: '已付款', color: 'warning', value: 3 },
        { text: '已发货', color: 'primary', value: 4 },
        { text: '已完成', color: 'success', value: 5 },
        { text: '已拒绝', color: '', value: 7 },
        // { text: '转售后', color: 'primary', value: 6 },
      ],
      statusListArray: [],
      statusValue: { text: '', color: '', value: '' },
      selected: [],
      overlay: false,
      broadcastInterval: null,
      cacheWaitConfirmCount: 0,
      speechPlugin: null, //语音合成插件
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '订单编号', value: 'orderNo' },
        { text: '收货信息', value: 'company' },
        { text: '订货金额', value: 'orderPrice' },
        { text: '下单时间', value: 'createdTime' },
        { text: '状态', value: 'orderStatus' },
      ]

      if (this.$can('order_toggle_status', 'order') || this.$can('order_detail', 'order')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.pages.page = 1
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      this.loadings.tableloading = true
      if (this.$route.query.id) {
        this.search.name = this.$route.query.id
        this.currentTab = 3
        this.status = undefined
        this.$route.query.id = undefined
      }
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        name: this.search.name,
        orderStatus: this.status,
      }
      OrderApi.getPages(searchData)
        .then(response => {
          this.loadings.tableloading = false
          const { pages, countDTO } = response.data.data
          this.orderCount = countDTO
          this.orders = pages.records
          // -1代表查询全部
          if (pages.size === -1) {
            this.pages.serverItemsLength = this.orders.length
          } else {
            this.pages.serverItemsLength = pages.total
          }

          this.cacheWaitConfirmCount = !!localStorage.getItem('OrderCacheWaitConfirmCount')
            ? parseInt(localStorage.getItem('OrderCacheWaitConfirmCount'), 0)
            : 0
          // 有新订单时进行语音播报
          if (this.cacheWaitConfirmCount >= 0 && this.cacheWaitConfirmCount < countDTO.waitConfirmCount) {
            this.audioBroadcast()
          }
          localStorage.setItem('OrderCacheWaitConfirmCount', countDTO.waitConfirmCount)
        })
        .catch(error => {
          this.loadings.tableloading = false
        })
    },
    initOrderDetail() {
      this.companyDetail = {}
      this.order = {}
      this.orderRecords = []
      this.orderDetails = []
      this.userInfo.memberName = ''
      this.userInfo.userName = ''
    },
    editItem(item) {
      this.initOrderDetail()
      if (item) {
        this.editOrder = item
        this.loadDetailData(item.orderNo)
      }
      this.editDialog = true
    },
    loadDetailData(orderNo) {
      if (this.$can('order_detail', 'order')) {
        OrderApi.getOrderDetail(orderNo).then(res => {
          const { detailResponse, memberName, userName } = res.data.data
          const { orderDTO, recordDTOS, detailDTOS, addressDTO } = detailResponse
          this.companyDetail = addressDTO
          this.order = orderDTO
          this.orderMemoinfos = orderDTO.orderMemo
          this.orderRecords = recordDTOS
          this.orderDetails = detailDTOS
          this.userInfo.memberName = memberName
          this.userInfo.userName = userName

          //遍历orderDetails，对item的productName中文进行排序
          this.orderDetails.sort((a, b) => {
            return a.productName.localeCompare(b.productName, 'zh')
          })
        })
      }
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      OrderApi.delete(this.deleteData.orderNo).then(() => {
        this.$toast.success('删除成功！')
        this.deleteData = {}
        this.loadPage(this.pages)
        this.deleteDialog = false
      })
    },
    reloadTab(status) {
      this.status = status

      this.toSearch()
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    getCurrentUserName() {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { fullName } = userData

      return fullName
    },
    getCurrentTime() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      const createdTime = `${year}-${month}-${day} ${hour}:${minute}`

      return createdTime
    },
    toggleStatus(orderNo, status) {
      OrderApi.toggleOrderStatus(orderNo, status, this.getCurrentUserName()).then(res => {
        this.$toast.success('操作成功')

        this.loadPage(this.pages)
        if (this.order) {
          this.order.orderStatus = status

          this.orderRecords.unshift({
            createdTime: this.getCurrentTime(),
            statusDesc: this.getOrderStatusByValue(status),
            createdBy: this.getCurrentUserName(),
          })
        }
      })
    },
    // 修改订单备注
    updateOrderMemo(orderNo, orderMemo) {
      OrderApi.updateOrderMemo(orderNo, orderMemo).then(() => {
        this.$toast.success('修改成功')
        this.orderMemoinfos = orderMemo
      })
    },

    getOrderStatusByValue(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.text : ''
    },
    getOrderStatusColor(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.color : ''
    },
    changeOrderStatus(val) {
      if (this.order) {
        this.toggleStatus(this.order.orderNo, val)
      }
      this.statusValue = { text: '', color: '', value: '' }
    },
    changeOrderMemo(orderMemo) {
      if (this.order && this.orderMemoinfos !== orderMemo) {
        this.updateOrderMemo(this.order.orderNo, orderMemo)
      }
    },
    modifyQuantity(quantity, item) {
      if (quantity === null || quantity === undefined || quantity === '') {
        this.$toast.error('数量不能为空')

        return
      }
      if (quantity === item.productSkuQuantity) {
        return
      }

      if (quantity < 1) {
        this.$toast.error('数量不能小于1')

        return
      }
      OrderApi.updateOrderProductQuantity(item.id, quantity).then(res => {
        this.$toast.success('修改成功')
        this.loadPage(this.pages)
        this.loadDetailData(this.order.orderNo)
      })
    },
    modifySettlementPrice(newPrice, item) {
      if (newPrice === null || newPrice === undefined || newPrice === '') {
        this.$toast.error('结算价不能为空')

        return
      }
      if (newPrice == item.settlementPrice) {
        return
      }

      const reg = /^\d+(\.\d{1,2})?$/
      if (!reg.test(newPrice)) {
        this.$toast.error('结算价最多只能2位小数')

        return
      }

      if (newPrice <= 0) {
        this.$toast.error('结算价不能小于0')
      }

      OrderApi.updateOrderProductPrice(item.id, newPrice).then(res => {
        this.$toast.success('修改成功')
        this.loadPage(this.pages)
        this.loadDetailData(this.order.orderNo)
      })
    },
    modifyOrderPrice(newPrice, item) {
      if (newPrice === null || newPrice === undefined || newPrice === '') {
        this.$toast.error('总价不能为空')

        return
      }
      if (newPrice == item.orderPrice) {
        return
      }

      if (newPrice > item.orderPrice) {
        this.$toast.error('总价不能大于原总价')

        return
      }

      const reg = /^\d+(\.\d{1,2})?$/
      if (!reg.test(newPrice)) {
        this.$toast.error('总价最多只能2位小数')

        return
      }

      if (newPrice <= 0) {
        this.$toast.error('总价不能小于0')
      }

      OrderApi.updateOrderPrice(item.orderNo, newPrice).then(res => {
        this.$toast.success('修改成功')

        this.loadPage(this.pages)
        this.loadDetailData(this.order.orderNo)
      })
    },
    exportOrder() {
      this.overlay = true
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }

      const exportData = {
        startTime,
        endTime,
        name: this.search.name,
        orderStatus: this.status,
        orderIds: this.selected.map(item => item.orderNo),
      }

      OrderApi.exportOrder(exportData)
        .then(response => {
          this.overlay = false
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          // 获取当前时间字符串
          const date = new Date()
          const year = date.getFullYear()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const hour = date.getHours().toString().padStart(2, '0')
          const minute = date.getMinutes().toString().padStart(2, '0')
          const fileName = `${year}-${month}-${day} ${hour}-${minute}`
          link.setAttribute('download', `订单数据-${fileName}.xls`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.overlay = false
        })
    },
    //有新订单时进行语音播报
    audioBroadcast() {
      // console.log('有新订单时进行语音播报')
      //播放语音
      window.speechSynthesis.speak(this.speechPlugin)
    },
    //初始化语音合成插件
    initSpeechPlugin() {
      // 创建实例
      this.speechPlugin = new SpeechSynthesisUtterance()
      // 播报完成回调函数
      this.speechPlugin.onend = function (event) {
        // console.log(event);
      }
      // 获取并设置话语的音调(0-2,值越大越尖锐,越低越低沉)
      // this.speechPlugin.pitch = 1;
      // 设置音量为0.8
      this.speechPlugin.volume = 0.8
      // 设置语速为1.5倍
      this.speechPlugin.rate = 1
      // 设置语言为简体中文  en-US
      this.speechPlugin.lang = 'zh-CN'
      // this.speechPlugin.lang = 'en-US';
      //设置语音内容
      this.speechPlugin.text = '您有新的订单,请及时处理'
      // this.speechPlugin.text = 'You You have a new order, please deal with it in time';
    },
    //page和size改变时触发
    updateOptions(options) {
      //重新赋值page和size
      this.pages.page = options.page
      this.pages.itemsPerPage = options.itemsPerPage
    },
  },
  mounted() {
    if ('speechSynthesis' in window) {
      //初始化插件
      this.initSpeechPlugin()

      //创建定时器，每10s刷新一次页面
      this.broadcastInterval = setInterval(() => {
        // console.log('刷新页面')
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })
      }, 30 * 1000)
    } else {
      // 浏览器不支持语音合成
      this.$toast.error('当前浏览器不支持语音播报,请使用Edge、Chrome等主流浏览器')
    }
  },
  beforeDestroy() {
    //销毁定时器
    // console.log('销毁定时器')
    clearInterval(this.broadcastInterval)
    // 停止所有正在进行的语音播报
    window.speechSynthesis.cancel()
    this.speechPlugin = null
  },
  watch: {
    editOrder(data) {
      if (!data) {
        this.statusListArray = this.statusList
      } else {
        const status = this.statusList.find(item => item.value === data.orderStatus)
        if (status.value == 0) {
          this.statusListArray = [{ text: '已取消', color: '', value: 1 }]
        } else if (status.value == 2) {
          this.statusListArray = [
            { text: '待确认', color: 'danger', value: 0 },
            { text: '已取消', color: '', value: 1 },
          ]
        } else if (status.value == 3) {
          this.statusListArray = [{ text: '已付款', color: 'warning', value: 3 }]
        } else if (status.value == 4) {
          this.statusListArray = [{ text: '已完成', color: 'success', value: 5 }]
        } else if (status.value == 5) {
          this.statusListArray = [{ text: '已完成', color: 'success', value: 5 }]
        } else if (status.value == 1) {
          this.statusListArray = [{ text: '待确认', color: 'danger', value: 0 }]
        } else if (status.value == 7) {
          this.statusListArray = [{ text: '已拒绝', color: '', value: 7 }]
        }
      }
    },
  },
}
</script>

<style>
.v-textarea[readonly] {
  cursor: not-allowed;
}
.fixed-top {
  position: fixed;
  top: 0;
  /* width: 100%; */
}
</style>
